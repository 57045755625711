import { Column, Grid } from 'components/Grid';
import RichTextRender from 'components/RichTextRender';
import { RichTextRenderProps } from 'components/RichTextRender/RichTextRender';
import { FC } from 'react';
import styled, { css } from 'styled-components';

interface RichTextContainerProps {
  responsive?: boolean;
}

export const RichTextContainer = styled.article<RichTextContainerProps>(
  ({ responsive = true, theme: { bp, colors } }) => css`
    font-size: 16px;
    line-height: 24px;

    ${bp.l} {
      font-size: ${responsive ? '18px' : '16px'};
      line-height: ${responsive ? '27px' : '24px'};
    }

    > * {
      margin: 0;
    }

    > h2 {
      font-size: 18px;
      line-height: 21px;
      margin-top: 32px;

      ${bp.l} {
        font-size: ${responsive ? '20px' : '18px'};
        line-height: ${responsive ? '23px' : '21px'};
      }
    }

    > h3 {
      font-size: 16px;
      line-height: 19px;
      margin-top: 32px;

      ${bp.l} {
        font-size: ${responsive ? '18px' : '16px'};
        line-height: ${responsive ? '21px' : '19px'};
      }
    }

    > hr {
      margin-top: 32px;
      border: none;
      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: ${colors.grey2};
    }

    > p,
    & > ul,
    & > ol {
      margin-top: 32px;
    }

    > h1 + p,
    & > h2 + p,
    & > h3 + p {
      margin-top: 16px;
    }

    > h1 + ul,
    & > h2 + ul,
    & > h3 + ul {
      margin-top: 16px;
    }

    > h1 + ol,
    & > h2 + ol,
    & > h3 + ol {
      margin-top: 16px;
    }

    > img {
      margin-top: 48px;
      width: 100%;
    }

    img + * {
      margin-top: 48px;
    }

    & > blockquote,
    & blockquote + * {
      margin-top: 8px;

      ${bp.l} {
        margin-top: ${responsive ? '12px' : '8px'};
      }
    }

    & > blockquote > p {
      font-size: 20px;
      line-height: 26px;
      text-align: center;

      ${bp.l} {
        font-size: ${responsive ? '24px' : '20px'};
        line-height: ${responsive ? '32px' : '26px'};
      }
    }

    > blockquote > p + p {
      font-size: 14px;
      line-height: 17px;
      margin-top: 16px;
    }

    > :first-child {
      margin-top: 0;
    }
  `
);

const RichText: FC<RichTextRenderProps> = ({ content }) => (
  <Grid columns={{ xs: 6, l: 12 }}>
    <Column starts={{ xs: 1, l: 4 }} spans={6}>
      <RichTextContainer>
        <RichTextRender content={content} />
      </RichTextContainer>
    </Column>
  </Grid>
);

export default RichText;
