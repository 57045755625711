import { BLOCKS, TopLevelBlock } from '@contentful/rich-text-types';
import Accordion, { AccordionItem } from 'components/Accordion/Accordion';
import { Column, Grid } from 'components/Grid';
import { RichTextContainer } from 'components/RichText/RichText';
import RichTextRender from 'components/RichTextRender';
import SizeGuideTable from 'components/SizeGuideTable/SizeGuideTable';
import { Title } from 'components/Typography';
import { SizeGuideTabModel } from 'models/contentful/sizeGuideTab/types';
import { SizeGuideTabsModel } from 'models/contentful/sizeGuideTabs/types';
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SizeGuideTabContainer = styled.div<{ visible: boolean }>(
  ({ visible }) => css`
    display: ${visible ? 'flex' : 'none'};
  `
);

const TabButtonContainer = styled.div`
  display: flex;
  gap: 32px;
`;

const TabButton = styled.button`
  background: none;
  border: none;
  font-weight: bold;
  border: 1px solid transparent;
  padding: 4px 0;
  cursor: pointer;

  &[aria-expanded='true'] {
    border-bottom: 1px solid black;
  }
`;

export type SizeGuideUnit = 'cm' | 'in';

type UnitPreferenceContentType = {
  unitPreference: SizeGuideUnit;
  setUnitPreference: Dispatch<SetStateAction<SizeGuideUnit>>;
};
export const UnitPreferenceContext = createContext<UnitPreferenceContentType>(
  {} as UnitPreferenceContentType
);

export const UnitPreferenceProvider = ({
  children,
  defaultUnit,
}: {
  children: ReactNode;
  defaultUnit?: SizeGuideUnit;
}) => {
  const [unitPreference, setUnitPreference] = useState<SizeGuideUnit>(
    defaultUnit || 'cm'
  );
  return (
    <UnitPreferenceContext.Provider
      value={{ unitPreference, setUnitPreference }}
    >
      {children}
    </UnitPreferenceContext.Provider>
  );
};

const SizeGuideTabs: FC<Omit<SizeGuideTabsModel, 'blockType'>> = ({
  introductionSection,
  tabs,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <Grid columns={{ xs: 6, l: 12 }}>
      <Column starts={{ xs: 1, l: 4 }} spans={6}>
        <Container>
          {introductionSection && (
            <RichTextContainer>
              <RichTextRender
                content={{
                  ...introductionSection,
                  content: introductionSection.content as TopLevelBlock[],
                  nodeType: BLOCKS.DOCUMENT,
                }}
              />
            </RichTextContainer>
          )}
          <UnitPreferenceProvider>
            <TabsContainer data-testid="size-guide-tabs-container">
              <TabButtonContainer>
                {tabs.map((tab, index) => (
                  <TabButton
                    key={tab.tabName + index}
                    data-testid={`tab-button-${index}`}
                    onClick={() => setSelectedTabIndex(index)}
                    aria-expanded={selectedTabIndex === index}
                  >
                    <Title variant="2" size="2XS" textAlign="center">
                      {tab.tabName}
                    </Title>
                  </TabButton>
                ))}
              </TabButtonContainer>

              {tabs.map((tab, index) => (
                <SizeGuideTab
                  key={`size-guide-tab-${index}`}
                  {...tab}
                  visible={index === selectedTabIndex}
                />
              ))}
            </TabsContainer>
          </UnitPreferenceProvider>
        </Container>
      </Column>
    </Grid>
  );
};

const SizeGuideTab: FC<SizeGuideTabModel & { visible: boolean }> = ({
  howToMeasureImage,
  howToMeasureDescription,
  tables,
  visible,
}) => {
  const items: AccordionItem[] = [
    ...(howToMeasureImage
      ? [
          {
            title: 'How to measure',
            children: (
              <HowToMeasureContent
                howToMeasureImage={howToMeasureImage}
                howToMeasureDescription={howToMeasureDescription}
              />
            ),
          },
        ]
      : []),
    ...tables.map(table => ({
      title: table.title || 'Size Guide',
      children: <SizeGuideTable {...table} />,
    })),
  ];

  return (
    <SizeGuideTabContainer visible={visible}>
      <Accordion items={items} multiple={true} />
    </SizeGuideTabContainer>
  );
};

const HowToMeasureContentContainer = styled.div(
  ({ theme: { bp } }) => css`
    display: grid;
    gap: 32px;
    ${bp.m} {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    img {
      width: 100%;
    }
  `
);

const HowToMeasureContent: FC<
  Pick<SizeGuideTabModel, 'howToMeasureImage' | 'howToMeasureDescription'>
> = ({ howToMeasureImage, howToMeasureDescription }) => {
  return (
    <HowToMeasureContentContainer>
      {howToMeasureImage && <img src={howToMeasureImage.url} alt="" />}
      {howToMeasureDescription && (
        <RichTextContainer>
          <RichTextRender
            content={{
              ...howToMeasureDescription,
              content: howToMeasureDescription.content as TopLevelBlock[],
              nodeType: BLOCKS.DOCUMENT,
            }}
          />
        </RichTextContainer>
      )}
    </HowToMeasureContentContainer>
  );
};

export default SizeGuideTabs;
