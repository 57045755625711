import { ResponsiveValue } from 'lib/toResponsiveValue';
import { generateResponsiveImageSrc } from 'lib/util';
import styled from 'styled-components';

interface ProductImageProps {
  src: string;
  alt: string;
  spans?: ResponsiveValue<number>;
  aspectRatio?: number;
  backgroundColor?: string;
  className?: string;
  thumbnailSize?: number;
  thumbnailBlur?: number;
}

const ProductImage = styled.img.attrs<ProductImageProps>(
  ({
    backgroundColor,
    src,
    spans,
    thumbnailBlur = 5,
    thumbnailSize = 160,
    theme,
  }) => ({
    backgroundColor: backgroundColor ?? theme.colors.grey4,
    src: generateResponsiveImageSrc(src, {
      aspectRatio: 4 / 5,
      spans,
    }),
    srcSet: generateResponsiveImageSrc(src, {
      aspectRatio: 4 / 5,
      spans,
      isSet: true,
    }),
    style: {
      backgroundImage: `url(${src}?auto=compress,format&cs=srgb&blur=${thumbnailBlur}&w=${thumbnailSize}&ar=${
        4 / 5
      })`,
    },
  })
)<ProductImageProps>`
  width: 100%;
  overflow: hidden;
  aspect-ratio: 4 / 5;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  object-fit: cover;
  overflow: hidden;
  user-select: none;
  color: transparent !important;
`;

export default ProductImage;
