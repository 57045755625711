import { FC, useMemo } from 'react';
import { Document, INLINES, MARKS, BLOCKS } from '@contentful/rich-text-types';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import {
  renderBlockEmbeddedAsset,
  renderBlockH1,
  renderBlockH2,
  renderBlockH3,
  renderBlockHr,
  renderBlockListItem,
  renderBlockOrderedList,
  renderBlockQuote,
  renderBlockUnorderedList,
  renderBoldMark,
  renderInlineHyperLink,
  renderItalicMark,
  renderText,
  renderUnderlineMark,
} from 'components/RichTextRender/helpers/renderers';
import { useRouter } from 'next/router';

export interface RichTextRenderProps {
  content: Document;
}

const getOptions = (locale: string): Options => ({
  renderMark: {
    [MARKS.BOLD]: renderBoldMark,
    [MARKS.ITALIC]: renderItalicMark,
    [MARKS.UNDERLINE]: renderUnderlineMark,
  },
  renderNode: {
    [INLINES.HYPERLINK]: renderInlineHyperLink(locale),
    [BLOCKS.HEADING_1]: renderBlockH1,
    [BLOCKS.HEADING_2]: renderBlockH2,
    [BLOCKS.HEADING_3]: renderBlockH3,
    [BLOCKS.QUOTE]: renderBlockQuote,
    [BLOCKS.LIST_ITEM]: renderBlockListItem,
    [BLOCKS.OL_LIST]: renderBlockOrderedList,
    [BLOCKS.UL_LIST]: renderBlockUnorderedList,
    [BLOCKS.HR]: renderBlockHr,
    [BLOCKS.EMBEDDED_ASSET]: renderBlockEmbeddedAsset,
  },
  renderText,
});

const RichTextRender: FC<RichTextRenderProps> = ({ content }) => {
  const {
    query: { locale },
  } = useRouter();

  const options = useMemo(() => getOptions(locale as string), [locale]);

  return <>{documentToReactComponents(content as Document, options)}</>;
};

export default RichTextRender;
