import { ProductListItem } from 'components/ProductList/ProductList';
import { getLocaleFromPath } from 'lib/locale';
import { useRouter } from 'next/router';
import qs from 'qs';
import { useEffect, useState } from 'react';

type UseRecommendations = (args: {
  element: string;
  productId?: string | typeof AWAITING_PRODUCT_ID;
}) => {
  loading: boolean;
  recommendations: ProductListItem[];
};

// Depending on the way recommendations are set up in GrapheneHC,
// we may or may not need to send a product ID with the request.
// If this hook is used in a circumstance in which the product ID
// is needed but its retrieval is deferred, please use this symbol
// in lieu of the product ID until it becomes available.
//
// @example
// const recommendations = useRecommendations({
//   element: 'cart_recs',
//   productId: retrievedIdValue ?? AWAITING_PRODUCT_ID,
// });
export const AWAITING_PRODUCT_ID = Symbol('AWAITING_PRODUCT_ID');

const useRecommendations: UseRecommendations = ({ element, productId }) => {
  const { asPath } = useRouter();
  const locale = getLocaleFromPath(asPath, 'en-us');

  const [loading, setLoading] = useState(true);
  const [recommendations, setRecommendations] = useState<ProductListItem[]>([]);

  useEffect(() => {
    if (productId === AWAITING_PRODUCT_ID) {
      return;
    }

    fetch(
      `/services/graphene/recommendations?${qs.stringify({
        element,
        productId,
        locale,
      })}`,
      {
        method: 'GET',
      }
    )
      .then<Graphene.Response<ProductListItem[]>>(response => response.json())
      .then(({ success, result }) => {
        if (success === true) {
          setRecommendations(result);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [element, productId, locale, setRecommendations, setLoading]);

  return { loading, recommendations };
};

export default useRecommendations;
