import {
  NodeRenderer,
  RenderMark,
  RenderText,
} from '@contentful/rich-text-react-renderer';
import { Block, Inline } from '@contentful/rich-text-types';
import Media from 'components/Media';
import { Asset } from 'contentful';
import { generateLocalizedUrl } from 'models/contentful/link/serializers';
import { toMediaSourceAsset } from 'models/contentful/media/serializers';
import RouterLink from 'next/link';
import { ReactNode } from 'react';
import styled from 'styled-components';

type Mark = RenderMark[keyof RenderMark];

const Strong = styled.strong`
  font-weight: 500;
`;

const Em = styled.em`
  font-style: italic;
`;

const Underline = styled.span`
  text-decoration: underline;
`;

const Hyperlink = styled.a`
  color: inherit;
  text-decoration: underline;
`;

const H1 = styled.h1`
  font-weight: 400;
  font-size: 64px;
  line-height: 72px;
`;

const H2 = styled.h2`
  font-weight: 400;
  font-size: 48px;
  line-height: 56px;
`;

const H3 = styled.h3`
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
`;

const Blockquote = styled.blockquote``;

const Li = styled.li``;

const Ol = styled.ol``;

const Ul = styled.ul``;

const Hr = styled.hr``;

export const renderBoldMark: Mark = node => <Strong>{node}</Strong>;

export const renderItalicMark: Mark = node => <Em>{node}</Em>;

export const renderUnderlineMark: Mark = node => <Underline>{node}</Underline>;

export const renderInlineHyperLink =
  (locale: string): NodeRenderer =>
  (node, children) =>
    (
      <RouterLink
        legacyBehavior={true}
        href={generateLocalizedUrl(node.data.uri, locale)}
        passHref={true}
      >
        <Hyperlink>{children}</Hyperlink>
      </RouterLink>
    );

export const renderBlockH1: NodeRenderer = (_, children) => <H1>{children}</H1>;

export const renderBlockH2: NodeRenderer = (_, children) => <H2>{children}</H2>;

export const renderBlockH3: NodeRenderer = (_, children) => <H3>{children}</H3>;

export const renderBlockQuote: NodeRenderer = (_, children) => (
  <Blockquote>{children}</Blockquote>
);

export const renderBlockListItem: NodeRenderer = (_, children) => (
  <Li>{children}</Li>
);

export const renderBlockOrderedList: NodeRenderer = (_, children) => (
  <Ol>{children}</Ol>
);

export const renderBlockUnorderedList: NodeRenderer = (_, children) => (
  <Ul>{children}</Ul>
);

export const renderBlockHr: NodeRenderer = () => <Hr />;

export const renderBlockEmbeddedAsset: NodeRenderer = (
  node: Block | Inline
) => {
  if (node.nodeType === 'embedded-asset-block') {
    const asset = node.data.target as Asset;

    const mediaSource = toMediaSourceAsset(asset.fields);

    return (
      <Media
        xs={{
          image: mediaSource,
          highResolutionVideo: null,
          lowResolutionVideo: null,
        }}
        s={null}
        m={null}
        l={null}
        xl={null}
        xxl={null}
        showVideoControls={false}
      />
    );
  }

  return null;
};

export const renderText: RenderText = text =>
  text
    .split('\n')
    .reduce<ReactNode[]>((children, textSegment, index) => {
      if (index > 0) {
        children.push(<br key={index} />);
      }

      children.push(textSegment);

      return children;
    }, [])
    .filter(node => node !== null);
