import { Chevron } from 'components/Icons';
import { Title } from 'components/Typography';
import { ReactNode, useId, useState } from 'react';
import styled, { css } from 'styled-components';

export interface AccordionItem {
  title: string;
  children: ReactNode;
}

interface AccordionProps {
  items: AccordionItem[];
  multiple: boolean;
}

interface AccordionItemProps {
  item: AccordionItem;
  expanded: boolean;
  toggleExpanded: () => void;
}

const AccordionList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 8px;
  width: 100%;
`;

const AccordionListItem = styled.li``;

const AccordionTrigger = styled.button`
  padding: 8px 0;
  width: 100%;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const AccordionContent = styled.div<{ expanded: boolean }>(
  ({ expanded }) => css`
    display: ${expanded ? 'flex' : 'none'};
    padding: 8px 0 56px 0;
  `
);

const AccordionItem = ({
  item: { title, children },
  expanded,
  toggleExpanded,
}: AccordionItemProps) => {
  return (
    <AccordionListItem>
      <AccordionTrigger
        onClick={toggleExpanded}
        aria-expanded={expanded}
        data-testid={`accordion-item-${title}`}
      >
        <Title variant="3" size="S">
          {title}
        </Title>
        <Chevron direction={expanded ? 'up' : 'down'} />
      </AccordionTrigger>
      <AccordionContent expanded={!!expanded}>{children}</AccordionContent>
    </AccordionListItem>
  );
};

const Accordion = ({ items, multiple }: AccordionProps) => {
  const [expandedItems, setExpandedItems] = useState([0]);

  const accordionId = useId();
  const toggleExpanded = (index: number) => {
    if (multiple) {
      if (expandedItems.includes(index)) {
        setExpandedItems(expandedItems.filter(items => items !== index));
      } else {
        setExpandedItems([...expandedItems, index]);
      }
    } else {
      setExpandedItems(expandedItems.includes(index) ? [] : [index]);
    }
  };

  return (
    <AccordionList>
      {items?.map((item, index) => (
        <AccordionItem
          key={`${accordionId}-${index}`}
          item={item}
          expanded={expandedItems.includes(index)}
          toggleExpanded={() => toggleExpanded(index)}
        />
      ))}
    </AccordionList>
  );
};

export default Accordion;
