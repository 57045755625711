import * as t from 'io-ts';
import { imageModel, priceModel } from 'models/variants/types';

export const productListingModel = t.interface({
  url: t.string,
  name: t.string,
  categoryOrderHint: t.number,
  sku: t.string,
  key: t.string,
  images: t.array(imageModel),
  price: priceModel,
  swatches: t.record(t.string, t.string),
  gender: t.string,
  tag: t.string,
  preorder: t.boolean,
});

export type ProductListingModel = t.TypeOf<typeof productListingModel>;
