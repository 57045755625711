import { UnitPreferenceContext } from 'components/SizeGuideTabs/SizeGuideTabs';
import { Title } from 'components/Typography';
import { SizeGuideTableModel } from 'models/contentful/sizeGuideTable/types';
import { useContext } from 'react';
import styled, { css } from 'styled-components';

const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  min-width: 100%;
`;
const TableBody = styled.tbody`
  > tr > td {
    padding: 12px;
    min-width: 50px;
  }

  tr:nth-child(even) {
    background: #e5e5e5;
  }

  > tr > th {
    padding: 12px;
    min-width: 50px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const SizeGuideTableContainer = styled.div(
  ({ theme: { bp } }) => css`
    width: 100%;
    overflow: scroll;
    padding-bottom: 16px;
    outline: unset;

    &:focus-visible {
      outline: Highlight auto 1px;
      outline: -webkit-focus-ring-color auto 1px;
    }

    ${bp.m} {
      overflow: auto;
      padding-bottom: 0;
    }
  `
);

const SizeGuideTitleRow = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`;

const SizeGuideTableButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
`;

const SizeGuideTableButton = styled.button`
  background: none;
  border: none;
  font-weight: bold;
  padding: 4px 0;
  border: 1px solid transparent;
  width: 25px;
  cursor: pointer;
  text-transform: uppercase;

  &[aria-expanded='true'] {
    border-bottom: 1px solid black;
  }
`;

const SizeGuideTable = ({
  tableDataCm,
  tableDataIn,
  showUnitTabs,
  productName,
}: SizeGuideTableModel & { productName?: string }) => {
  const { unitPreference, setUnitPreference } = useContext(
    UnitPreferenceContext
  );

  const selectedTable =
    showUnitTabs && unitPreference === 'in' ? tableDataIn : tableDataCm;

  return (
    <Container>
      {showUnitTabs ? (
        <SizeGuideTitleRow>
          <Title variant="3" size="XS">
            {productName} Size Guide
          </Title>
          <SizeGuideTableButtonContainer>
            <SizeGuideTableButton
              onClick={() => setUnitPreference('cm')}
              aria-expanded={unitPreference === 'cm'}
              data-testid="cm-button"
            >
              <Title variant="2" size="XS" textAlign="center">
                cm
              </Title>
            </SizeGuideTableButton>
            <SizeGuideTableButton
              onClick={() => setUnitPreference('in')}
              aria-expanded={unitPreference === 'in'}
              data-testid="in-button"
            >
              <Title variant="2" size="XS" textAlign="center">
                in
              </Title>
            </SizeGuideTableButton>
          </SizeGuideTableButtonContainer>
        </SizeGuideTitleRow>
      ) : (
        <SizeGuideTitleRow>
          <Title variant="3" size="XS">
            {productName} Size Guide
          </Title>
        </SizeGuideTitleRow>
      )}
      <SizeGuideTableContainer tabIndex={0}>
        <Table data-size-guide>
          <TableBody>
            {selectedTable.tableData.map((row, rowIndex) => (
              <tr key={'tr-' + rowIndex}>
                {row.map((column, columnIndex) => {
                  if (rowIndex === 0 && selectedTable.useColumnHeader) {
                    return (
                      <Title
                        key={'th-' + columnIndex}
                        variant="3"
                        size="2XS"
                        as="th"
                        textAlign="center"
                      >
                        {column}
                      </Title>
                    );
                  }

                  if (columnIndex === 0 && selectedTable.useRowHeader) {
                    return (
                      <Title
                        key={'th-' + columnIndex}
                        variant="3"
                        size="2XS"
                        as="th"
                        textAlign="left"
                      >
                        {column}
                      </Title>
                    );
                  }

                  return (
                    <Title
                      key={'td-' + columnIndex}
                      variant="1"
                      size="2XS"
                      as="td"
                      textAlign="center"
                    >
                      {column}
                    </Title>
                  );
                })}
              </tr>
            ))}
          </TableBody>
        </Table>
      </SizeGuideTableContainer>
    </Container>
  );
};

export default SizeGuideTable;
