import * as t from 'io-ts';

export const sizeGuideTableModel = t.exact(
  t.interface({
    title: t.union([t.string, t.null]),
    tableDataCm: t.exact(
      t.interface({
        tableData: t.array(t.array(t.union([t.null, t.string]))),
        useRowHeader: t.boolean,
        useColumnHeader: t.boolean,
      })
    ),
    tableDataIn: t.exact(
      t.interface({
        tableData: t.array(t.array(t.union([t.null, t.string]))),
        useRowHeader: t.boolean,
        useColumnHeader: t.boolean,
      })
    ),
    showUnitTabs: t.boolean,
  }),
  'SizeGuideTable'
);

export type SizeGuideTableModel = t.TypeOf<typeof sizeGuideTableModel>;
