import { type Document } from '@contentful/rich-text-types';
import * as t from 'io-ts';

export const document = t.interface(
  {
    nodeType: t.string,
    content: t.array(t.unknown),
    data: t.record(t.string, t.unknown),
  },
  'Document'
);

export const richTextModel = t.interface(
  {
    content: document,
    blockType: t.literal('RichText'),
  },
  'RichText'
);

export interface RichTextModel extends t.TypeOf<typeof richTextModel> {
  content: Document;
  blockType: 'RichText';
}
